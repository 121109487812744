import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Contact/PageBanner";
import ContactInfo from "../components/Contact/ContactInfo";
import ContactForm from "../components/Contact/ContactForm";
import ContactFooter from "../components/Contact/ContactFooter";
const Contact = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner pageTitle="Contact Us" />
      <ContactInfo />
      <ContactForm />
      <ContactFooter />
    </Layout>
  );
};

export default Contact;
