import React from "react";

const ContactForm = () => {
  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <h2>
            Got a question?
            <br />
            <small>We're here to help</small>
          </h2>
        </div>
        <div className="">
          <div className="contact-form mx-auto">
            <form id="contactForm">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input type="text" name="name" className="form-control" required placeholder="Your name" />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input type="email" name="email" className="form-control" required placeholder="Your email address" />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input type="text" name="phone_number" className="form-control" required placeholder="Your phone number" />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." />
                  </div>
                </div>

                <div className="col-md-12">
                  <button type="submit" className="default-btn">
                    Send Message <span></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
