import React from "react";
import { Link } from "gatsby";
import logo from "../../assets/images/footer-logo.png";

const ContactFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="single-footer-widget text-center contact-footer">
          <a href="/" className="logo">
            <img src={logo} alt="logo" />
          </a>
          <ul className="social-link">
            <li>
              <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                <i className="bx bxl-facebook"></i>
              </Link>
            </li>
            <li>
              <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                <i className="bx bxl-twitter"></i>
              </Link>
            </li>
            <li>
              <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                <i className="bx bxl-instagram "></i>
              </Link>
            </li>
            <li>
              <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                <i className="bx bxl-linkedin"></i>
              </Link>
            </li>
          </ul>

          <ul className="footer-links-list pt-3 pt-md-5 mx-auto w-50 d-flex justify-content-around flex-column flex-md-row">
            <li>
              <Link to="/">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom-area text-center">
        <p>©{currentYear} Inovett.
        </p>
      </div>
    </footer>
  );
};

export default ContactFooter;
